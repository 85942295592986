

enum AppMode {
  PRESENTATION = "presentation",
  TEXT = "text",
}

enum MessageType {
  LECTURE = "lecture",
  CHAT = "chat",
}

enum PromptType {
  GENERAL = "generalPrompt",
  QUESTION_ANSWER = "questionAnswer",
}

enum SpeakerType {
  INSTRUCTOR = "instructor",
  STUDENT = "student",
}


export {
  AppMode,
  MessageType,
  PromptType,

  SpeakerType,
}

