
import "../css/styles.css";


import { useState, useEffect, useContext, createContext, useCallback, useRef } from "react";

//import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import { DEBUG, DEBUG_VOICE_SPEED, DEFAULT_NOTIFICATION_LIFESPAN, FAKE_STUDENT_VOICES, IDLE_INSTRUCTOR_VIDEO, INSTRUCTOR_VOICES, MAX_WAIT_BEFORE_TRY_SPEAK, MIN_SILENCE_BEFORE_NEXT_SPEAK, QUESTIONS_PAUSED_NOTIFICATION, USE_BROWSER_SPEECH_SYNTHESIS_API, VERBOSE, VOICE_SPEED } from "../common/constants";
import { MessageType } from "../types/enums";


import { useSession, useSessionUpdate } from "./SessionContext";
import { useMessageUpdate } from "./MessageContext";
import { usePromptUpdate } from "./PromptContext";

import ReportsService from "../services/reports";

import TimeService from "../services/time";


export {
  SpeechRecognitionContextProvider,
  useSpeechRecognition, useSpeechRecognitionUpdate
};


const SpeechRecognitionContext = createContext<any>( null ); // TODO: Properly fill out

const SpeechRecognitionUpdateContext = createContext<any>( null ); // TODO: Properly fill out


function useSpeechRecognition() {
  return useContext( SpeechRecognitionContext );
}

function useSpeechRecognitionUpdate() {
  return useContext( SpeechRecognitionUpdateContext );
}






function SpeechRecognitionContextProvider( {children}: ComponentProps ) {


  const { speechRecognition, questionsAllowed } = useSession();
  const { setSpeechRecognition, } = useSessionUpdate();

  const { postMessage, postMessageAndSpeakInChunks, streamAssistantResponse } = useMessageUpdate();

  const { handleQuestion } = usePromptUpdate();



  useEffect( () => {
    if( questionsAllowed ) {
      recognizeSpeech();
    }
    else {
      //stopSpeechRecognition();
    }
  }, [questionsAllowed]);



  const recognizeSpeech = useCallback( () => {
  }, []);

  const stopSpeechRecognition = useCallback( () => {

  }, [ speechRecognition ]);



  return (
    <SpeechRecognitionContext.Provider value={ {} } >
      <SpeechRecognitionUpdateContext.Provider value={ {recognizeSpeech, stopSpeechRecognition} } >
        {children}
      </SpeechRecognitionUpdateContext.Provider>
    </SpeechRecognitionContext.Provider>
  );


}













