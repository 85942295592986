
import "../css/styles.css";


export default function ChatHead() {

  return (
    <div id="TEST" style={ {position: "absolute", top: 0, right: 0, "color": "white", zIndex: 1} }>
      <div id="video-wrapper">
        <div>
          <video id="talk-video" width="170" height="170" autoPlay style={ {position: "fixed", left: "80%", top: "10px", borderRadius: "50%", zIndex: 1} }></video>
        </div>
      </div>

      <br />
    </div>
  );
}
