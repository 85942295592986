

import "../css/styles.css";

import { SpeechRecognitionContextProvider } from "../context/SpeechRecognitionContext";
import { SpeechSynthesisContextProvider } from "../context/SpeechSynthesisContext";
import { PresentationContextProvider } from "../context/PresentationContext";
import { MessageContextProvider } from "../context/MessageContext";
import { PromptContextProvider } from "../context/PromptContext";
import { useSession } from "../context/SessionContext";


import LectureContainer from "./LectureContainer";
import ChatContainer from "./ChatContainer";

import { AppMode } from "../types/enums";
import PresentationContainer from "./PresentationContainer";


export default function AppBody() {

  const { appMode } = useSession();

  const renderAppMode = () => {

    switch( appMode ) {
      case AppMode.PRESENTATION: {
        return (
          <PresentationContextProvider >
            <PresentationContainer />
          </PresentationContextProvider>
        );
      }

      case AppMode.TEXT: {
        return <LectureContainer />
      }
    }
  };

  return (
    <div className="container-body">
      <SpeechSynthesisContextProvider>
        <PromptContextProvider>

          <MessageContextProvider >
            <SpeechRecognitionContextProvider>

              { renderAppMode() }
              {/* TODO: Was considering a Context for overlapping code between Lecture and Presentation, such as the Navigation means (Start/Pause/Play, Next)
              <InstructionContext>
                { renderAppMode() }
              </InstructionContext>
              */}

              <ChatContainer />
            </SpeechRecognitionContextProvider>
          </MessageContextProvider>

        </PromptContextProvider>
      </SpeechSynthesisContextProvider>
    </div>
  );
}


