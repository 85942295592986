


import { SERVER_ORIGIN } from "../common/constants"






class ReportsService {

	// ============================= BUG AND FEEDBACK ===============================
	public static reportBug() {
		const url = `${SERVER_ORIGIN}/api/debug`;

		// TODO: Need to send some sort of Session Details dump to server for Context tracking... (consider what that will now be)
		const sessionDetails = {};

		fetch( url, {
			method: "POST",
			credentials: "include",

			headers: {
				"Content-Type": "application/json"
			},

			body: JSON.stringify({ lectureDetails: sessionDetails }), // TODO: Conflict in names between Client/Server needs reconciled (i.e. lectureDetails is what Client calls SessionDetails and SessionDetails should be information about User's Session)
		});
	}

	public static reportLaunch( failed = false ) {
		const launchType = failed ? "FAILURE" : "SUCCESS";
		const url = `${SERVER_ORIGIN}/api/debug/launch?type=${launchType}`;

		fetch( url, {
			method: "POST",
			credentials: "include",

			headers: {
				"Content-Type": "application/json"
			},
		});
	}

	public static reportCrash( crashDetails: any ) { // TODO: TYPE
		const url = `${SERVER_ORIGIN}/api/debug/crash`;

		fetch( url, {
			method: "POST",
			credentials: "include",

			headers: {
				"Content-Type": "application/json"
			},

			body: JSON.stringify({ crashDetails }), // TODO: Conflict in names between Client/Server needs reconciled (i.e. lectureDetails is what Client calls SessionDetails and SessionDetails should be information about User's Session)
		});
	}

	public static reportFreeze() {
		const url = `${SERVER_ORIGIN}/api/debug/freeze`;

		fetch( url, {
			method: "POST",
			credentials: "include",

			headers: {
				"Content-Type": "application/json"
			},
		});
	}

	// =============================================================================

	// =========================== INTERACTION REPORTS =============================
	public static reportClose() {
		const url = `${SERVER_ORIGIN}/api/debug/close`;

		// sendBeacon API is useful when not expect Response back and want lightweight data transportation to Server (e.g. great for Analytics)
		navigator.sendBeacon(url);
	}

	public static reportPause() {
		const url = `${SERVER_ORIGIN}/api/debug/pause`;

		navigator.sendBeacon(url);
	}

	public static reportPlay() {
		const url = `${SERVER_ORIGIN}/api/debug/play`;

		navigator.sendBeacon(url);
	}

	public static reportPreviousClick() {
		const url = `${SERVER_ORIGIN}/api/debug/previous`;

		navigator.sendBeacon(url);
	}

	public static reportNextClick() {
		const url = `${SERVER_ORIGIN}/api/debug/next`;

		navigator.sendBeacon(url);
	}
	// =============================================================================
}


export default ReportsService;
