

import { useState, useEffect, useContext, createContext, useCallback, useRef } from "react";


import { DEBUG, INSTRUCTOR_NAME, INSTRUCTOR_PROFILE_PIC_SRC, QUESTIONS_PAUSED_NOTIFICATION, SERVER_WEBSOCKETS_ORIGIN, USE_BROWSER_SPEECH_SYNTHESIS_API, VERBOSE, WAIT_UNTIL_NO_ONE_SPEAKING_NOTIFICATION } from "../common/constants";

import { useNotification } from "./NotificationContext";
import { useSession, useSessionUpdate } from "./SessionContext";
import { usePresentation } from "./PresentationContext";
import { useProfileUpdate } from "./ProfileContext";

import { useSpeechRecognition } from 'react-speech-recognition';

import { useSpeechSynthesis, useSpeechSynthesisUpdate } from "./SpeechSynthesisContext";
import { MessageType, PromptType } from "../types/enums";


export {
  MessageContextProvider,
  useMessage, useMessageUpdate
};


const MessageContext = createContext<any>( null ); // TODO: Properly fill out

const MessageUpdateContext = createContext<any>( null ); // TODO: Properly fill out


function useMessage() {
  return useContext( MessageContext );
}

function useMessageUpdate() {
  return useContext( MessageUpdateContext );
}






function MessageContextProvider( {children}: ComponentProps ) {

  const [ messages, setMessages ] = useState<IMessage[]>( [] );
  const [ lectureMessages, setLectureMessages ] = useState<IMessage[]>( [] ); // TODO: Perhaps have a higher-level Context that abstracts overlap so can then have MessageContext and LectureContext (would then rename messages to something like chatMessages)
  const [ highlightedMessageId, setHighlightedMessageId ] = useState<number | undefined>( );

  const { getProfilePicSource } = useProfileUpdate();


  const messageQueue = useRef<any[]>( [] );
  const isProcessing = useRef<boolean>( false );

  const firstUnusedMessageId = useRef<number>( 0 ); // Used since Refs allow for changes within same Render to be used and persist across re-renders (i.e. acts like state)

  // TODO: I don't ever use currentMessage anymore...
  // ... So remove this and all setCurrentMessage(...) calls?
  const [ currentMessage, setCurrentMessage ] = useState<IMessage>({
    profilePicSource: INSTRUCTOR_PROFILE_PIC_SRC,
    profileName: "",
    messageText: "",

    id: ++firstUnusedMessageId.current,
  });

  const {
    listening,

    transcript,
    resetTranscript: resetAudioTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();



  const { questionsAllowed } = useSession();
  const { muteStudents, unmuteStudents, addTextToTranscript } = useSessionUpdate();

  const { postNotification } = useNotification();

  const { speak } = useSpeechSynthesis();
  const { cancelSpeechSynthesis } = useSpeechSynthesisUpdate();



  const startAudioEvent = USE_BROWSER_SPEECH_SYNTHESIS_API ? "start" : "play";
  const pauseAudioEvent = USE_BROWSER_SPEECH_SYNTHESIS_API ? "um??pause?" : "pause";
  const endAudioEvent = USE_BROWSER_SPEECH_SYNTHESIS_API ? "end" : "ended";
  const abortAudioEvent = USE_BROWSER_SPEECH_SYNTHESIS_API ? "um??abort?" : "abort";




  // ############################ QUEUE PROCESSING ############################
  // ################### TODO: This whole chunk of code may be best in PromptContext, since "prompt-aware", but would need to pass "handler" functions as args to some of these, such as processQueue -> I.e. change "postMessageAndSpeak" with something like "messageHandler" and "setCurrentMessage" to something like "messageUpdateHandler"
  const processQueue = useCallback( async () => {
    if( isProcessing.current || messageQueue.current.length === 0 ) {
      // TODO: Perhaps if somehow access isPaused (currently in PresentationContainer only), then maybe can fix issue with speaking when it's paused??
      //if( isPaused ) return; // TODO: May not need as commenting out "isProcessing.current" seemed to work
      if( questionsAllowed && messageQueue.current.length === 0 ) unmuteStudents();

      return;
    }

    isProcessing.current = true;
    // TODO: PERFORMANCE: .shift() requires moving whole array around -- SLOW -- Find better way... (built-in Queue Object in JavaScript?)
    const messageOptions: any = messageQueue.current.shift();

    const utterance = await postMessageAndSpeak( messageOptions, { unmuteAfter: false });
    utterance.play();

    utterance.addEventListener( endAudioEvent, () => {
      isProcessing.current = false;
      console.log("AUDIO TRANSCRIPT (PROCESSING QUEUE)...", transcript);
      resetAudioTranscript();
      processQueue();

      if( messageOptions.onCompleteHandler ) {
        messageOptions.onCompleteHandler();
      }
    });

    utterance.addEventListener( pauseAudioEvent, () => {
      //isProcessing.current = false;
    });
    utterance.addEventListener( startAudioEvent, () => {
      //isProcessing.current = true;
    });

  }, []);

  const enqueueMessage = useCallback( (messageOptions: any) => {
    messageQueue.current.push( messageOptions );
    processQueue();
  }, [processQueue]);

  const clearMessageQueue = useCallback( () => {
    messageQueue.current = [];
    isProcessing.current = false;
  }, [messageQueue]);

  const handleMessageStream = useCallback( async (data: string, type: string) => {

    let readyToSend = false;
    const delimiters = [ "?", ".", "!" ]; // TODO: Move elsewhere or as param? (similar for 150 characters -- make param or constant elsewhere...)
    const CHARACTER_LIMIT = 150;

    setCurrentMessage( (prev: any) => {
      const updatedText = prev.messageText + data;

      // Once text is beyond our Max Characters, start looking for delimiter.
      if( updatedText.length > CHARACTER_LIMIT ) {
        readyToSend = delimiters.some( delimiter => data.includes(delimiter) );
      }

      if( readyToSend ) {
        // TODO: Right now just taking all of updatedText, but it may have a period and then some more after (NOT VERIFIED) -- Hence, may need to make this more sophisticated to find the location where last delmiter is and slice or split it based on that
        const finishedMessage = { ...prev, messageText: updatedText };
        const remainingText = ""; //updatedText.slice( 150 );

        enqueueMessage({ message: finishedMessage.messageText, sender: INSTRUCTOR_NAME, type: type });

        const profilePicSource = getProfilePicSource( INSTRUCTOR_NAME ); // TODO: Generalize to some Input... (that gets defaulted to INSTRUCTOR_NAME like elsewhere)

        return {
          profilePicSource: profilePicSource,
          profileName: INSTRUCTOR_NAME,
          messageText: remainingText,
          id: ++firstUnusedMessageId.current,
        };
      }
      else {
        return { ...prev, messageText: updatedText };
      }
    });
  }, [enqueueMessage]);

  // ##########################################################################



  // ################################ STREAMING ###############################
  const finalizeCurrentMessage = useCallback( (messageType: string) => {
    setCurrentMessage( (prev: any) => {
      if( prev?.messageText.length > 0 ) {
        const finalMessage = { ...prev };

        // TODO: HARDCODED sender INSTRUCTOR_NAME
        enqueueMessage({ message: prev.messageText, sender: INSTRUCTOR_NAME, type: messageType });

        return {
          profileName: INSTRUCTOR_NAME,
          messageText: "",
          id: ++firstUnusedMessageId.current,
        };
      }

      return prev;
    });
  }, [enqueueMessage]);

  const streamAssistantResponse = useCallback(async ({ context, prompt, promptType = PromptType.QUESTION_ANSWER, messageType = MessageType.CHAT }: any) => {

    try {

      switch( promptType ) {

        case PromptType.QUESTION_ANSWER: {
          break;
        }

        case PromptType.GENERAL: {
          break;
        }
      }

      const ws = new WebSocket( SERVER_WEBSOCKETS_ORIGIN );

      ws.onopen = () => {
        console.log( "WebSocket Connected" );
        ws.send( JSON.stringify({type: "streamAssistant", payload: prompt}) );
      };

      ws.onmessage = ( event ) => {
        const message = JSON.parse( event.data as string );

        if( message.type === "data" ) {
          handleMessageStream( message.data, messageType );
        }
        else if( message.type === "end" ) {
          finalizeCurrentMessage( messageType );
        }
      };

      ws.onerror = () => {
        console.error( "ERROR: WebSocket encountered an error" );
      };
    }
    catch (error) {
      console.error('Error:', error);
    }
  }, []);

  const processLecturePage = useCallback( async ( text: string) => {
    const messageOptions = { message: text, sender: INSTRUCTOR_NAME, type: MessageType.LECTURE };
    const utterance = await postMessageAndSpeak( messageOptions, { unmuteAfter: false });

    return utterance;
  }, []);
  // ##########################################################################




  // ################################ CHUNKING ################################
  // TODO: Consider renaming to include something about "Queue" since this doesn't return any utterance but just queues up something...?
  const postMessageAndSpeakInChunks = useCallback( async ( messageOptions: any, speakOptions: any, onCompleteHandler?: () => void ) => { // TODO: TYPE
    const message = messageOptions.message;
    const sender = messageOptions.sender;
    const type = messageOptions.type;

    const delimiter = ". ";
    const delimiterOccurrenceCount = 2;
    const addDelimiterBack = true;
    const delimiterAlternatives = ["!", "?"];
    //const chunks = splitAndCombineByDelimiter(message, delimiter, delimiterOccurrenceCount, addDelimiterBack, delimiterAlternatives);
    const delimiters = [ ". ", "?", "!" ]; // ". " to prevent breaking up website addresses (e.g. scratch.mit.edu)
    const chunks = splitAndCombineByDelimiters( message, delimiters, delimiterOccurrenceCount, addDelimiterBack );

    muteStudents();

    console.log("EACHING ON CHUNKS: ", chunks);

    chunks.forEach(async (chunk, index) => {

      // TODO: Below is not being used at the moment (enqueueMessage not support speakOptions right now...)
      // ... speakOptions passed into this function isn't being used at all right now either
      let updatedSpeakOptions = speakOptions;
      if (index < chunks.length - 1) {
        const shouldUnmuteAfter = false; //speakOptions ? (speakOptions.unmuteAfter ? speakOptions.unmuteAfter : undefined) : false;
        updatedSpeakOptions = { ...speakOptions, unmuteAfter: shouldUnmuteAfter };
      }

      console.log("ENQUEING Chunk: ", chunk);
      const completionHandler = index === chunks.length - 1 ? onCompleteHandler : undefined;
      enqueueMessage({ message: chunk, sender: sender, type: type, onCompleteHandler: completionHandler });
    });
  }, [enqueueMessage]);


  const splitAndCombineByDelimiters = useCallback( ( text: string, delimiters: string[], delimiterOccurrenceCount: number, addDelimiterBack: boolean, minCharacterCount: number = 100 ) => {
      // Create a regex pattern to split the text by any of the delimiters, retaining delimiters
      const delimiterPattern = new RegExp(`(${delimiters.map(d => `\\${d}`).join('|')})`);

      // Split the text and keep the delimiters as separate parts
      let parts = text.split(delimiterPattern);
      
      const chunks = [];
      let tempChunk = '';
      let delimiterCount = 0;
      let characterCount = 0;

      // Iterate over the parts and manage the delimiter count to combine text and delimiters correctly
      for (let i = 0; i < parts.length; i++) {
          const part = parts[i];
          const nextPart = parts[i + 1];

          // Check if the next part is a delimiter
          if (delimiters.includes(nextPart)) {
              tempChunk += part + nextPart;
              characterCount += part.length + nextPart.length;

              delimiterCount++;
              i++; // Skip the next part as it's a delimiter that we've already added
          } else {
              tempChunk += part;
          }

          // If we've reached the delimiter occurrence count, push the chunk and reset
          if( delimiterCount === delimiterOccurrenceCount || (characterCount > minCharacterCount && delimiters.includes(nextPart)) ) {
              chunks.push(tempChunk);
              tempChunk = '';
              delimiterCount = 0;
              characterCount = 0;
          }
      }

      // Ensure any remaining text is added as the last chunk
      if (tempChunk.trim().length > 0) {
          chunks.push(tempChunk);
      }

      return chunks;
  }, []);
  // ##########################################################################



  interface PostMessageProps {
    messageText: string;
    attachedFileName?: string;

    senderName: string;

    type: string;
  }
  // ############################ MESSAGE CREATION ############################
  const postMessage = useCallback( ({ messageText, attachedFileName, senderName = INSTRUCTOR_NAME, type = MessageType.CHAT }: PostMessageProps) => {

    const newMessage = createMessage( messageText, senderName, attachedFileName );
    addTextToTranscript( messageText, senderName );

    switch( type ) {
      case MessageType.CHAT: {
        setMessages( previousMessages => [...previousMessages, newMessage] );

        break;
      }

      case MessageType.LECTURE: {
        setLectureMessages( previousMessages => [...previousMessages, newMessage] );

        break;
      }

      default: {
        console.error( `Unrecognized Message Type: ${type}` );
        break;
      }
    }

    return newMessage;
  }, []);

  const createMessage = useCallback( ( messageText: string, senderName: string, attachedFileName?: string ) => {

    const profilePicSource = getProfilePicSource( senderName );

    const newMessage: IMessage = {
      profileName: senderName,
      profilePicSource: profilePicSource,
      messageText: messageText,

      attachedFileName: attachedFileName,

      id: firstUnusedMessageId.current,
    }

    firstUnusedMessageId.current += 1;

    return newMessage;
  }, []);


  const postMessageAndSpeak = useCallback( async ( messageOptions: any, speakOptions: any ) => { // TODO: TYPE

    const message = messageOptions.message;
    const sender = messageOptions.sender;
    const type = messageOptions.type;

    const newMessage: IMessage = postMessage({ messageText: message, senderName: sender, type: type });

    speakOptions.speaker = sender;
    const utterance = await speak( message, speakOptions );

    const addHighlight = () => setHighlightedMessageId( newMessage.id );
    const removeHighlight = () => {
      setHighlightedMessageId( undefined )
      utterance.removeEventListener( startAudioEvent, addHighlight );
    };


    // TODO: USE THE BELOW INSTEAD OF IF / ELSE (once properly using USE_BROWSER_SPEECH_SYNTHESIS again)
    utterance.addEventListener( startAudioEvent, addHighlight );
    utterance.addEventListener( endAudioEvent, removeHighlight );

    return utterance;
  }, []);
  // ##########################################################################



  const stopAllSpeech = () => {
    clearMessageQueue();
    cancelSpeechSynthesis();
    setHighlightedMessageId( undefined );
  }



  const clearMessages = useCallback( () => { // Clears Message Box of all Messages
    setMessages( [] );
  }, []);





  return (
    <MessageContext.Provider value={ {messages, lectureMessages, highlightedMessageId} } >
      <MessageUpdateContext.Provider value={ {postMessage, postMessageAndSpeak, postMessageAndSpeakInChunks, 
                                              streamAssistantResponse, stopAllSpeech,
                                              processLecturePage, clearMessageQueue } } >
        {children}
      </MessageUpdateContext.Provider>
    </MessageContext.Provider>
  );


}







