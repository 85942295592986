
import "../css/styles.css";


import { useState, useEffect, useContext, createContext } from "react";


import { DEFAULT_NOTIFICATIONS_ENABLED, DEFAULT_NOTIFICATION_LIFESPAN } from "../common/constants";

import { useSession } from "./SessionContext";


export {
  NotificationContextProvider,
  useNotification, useNotificationUpdate
};


const NotificationContext = createContext<any>( null ); // TODO: Properly fill out

const NotificationUpdateContext = createContext<any>( null ); // TODO: Properly fill out


function useNotification() {
  return useContext( NotificationContext );
}

function useNotificationUpdate() {
  return useContext( NotificationUpdateContext );
}


interface INotification {
  message: string;

  id: number;
}



function NotificationContextProvider( {children}: ComponentProps ) {

  const [ notifications, setNotifications ] = useState<INotification[]>( [] );

  const [ notificationsEnabled, setNotificationsEnabled ] = useState<boolean>( DEFAULT_NOTIFICATIONS_ENABLED );


  const postNotification = ( message: string, duration: number = DEFAULT_NOTIFICATION_LIFESPAN ) => {

    if( !notificationsEnabled ) return;

    const id = new Date().getTime(); // uniqueID for each Notification
    setNotifications( previous => [...previous, {message, id}] );

    setTimeout( () => {
      setNotifications( previous => previous.filter(notification => notification.id !== id) );
    }, duration);
  }

  const removeNotification = ( notificationToDelete: INotification ) => {
    setNotifications( previous => {
      return previous.filter( (notification: INotification) => (
        notification.id !== notificationToDelete.id
      ))
    });
  }

  return (
    <NotificationContext.Provider value={ {} } >
      <NotificationUpdateContext.Provider value={ {postNotification} } >
        <div id="container-notifications">
          {notifications.map( (notification: INotification) => (
              <Notification notification={notification} deleteNotificationHandler={removeNotification} key={notification.id} />
          ))}
        </div>

        {children}
      </NotificationUpdateContext.Provider>
    </NotificationContext.Provider>
  );

}


interface NotificationProps {
  notification: INotification,

  deleteNotificationHandler: any,
}
function Notification({ notification, deleteNotificationHandler }: NotificationProps) {

  return (
    <div id="container-notifications" onClick={() => deleteNotificationHandler(notification)}>
      <div className="notification slide-down">
        { notification.message }
      </div>
    </div>
  );
}






