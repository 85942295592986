


class TimeService {



  public static delay( milliseconds: number ) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }


}


export default TimeService;

