

import "../css/styles.css";


export default function Header() {

  return (
    <div className="container-header">
        <h1 className="logo">{`AI<4>Youth`}</h1>
    </div>
  );
}


