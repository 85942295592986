

//import "../css/styles.css";

import { useState, useEffect, useContext, createContext, useCallback } from "react";

import { useSession, useSessionUpdate } from "../context/SessionContext";
import { useNotificationUpdate } from "./NotificationContext";

import { useProfileUpdate } from "./ProfileContext";

import { fetchModelResponse, fetchAssistantResponse } from "../services/fetch";


import ReportsService from "../services/reports";

import { QUESTIONS_PAUSED_NOTIFICATION, QUESTION_LIMIT_MESSAGE, SERVER_ERROR_TEXT, SERVER_ORIGIN, SERVER_WEBSOCKETS_ORIGIN, USE_ASSISTANTS } from "../common/constants";
import { MessageType, PromptType } from "../types/enums";






export {
  PromptContextProvider,
  usePrompt, usePromptUpdate
};


const PromptContext = createContext<any>( null ); // TODO: Properly fill out

const PromptUpdateContext = createContext<any>( null ); // TODO: Properly fill out


function usePrompt() {
  return useContext( PromptContext );
}

function usePromptUpdate() {
  return useContext( PromptUpdateContext );
}


function PromptContextProvider( {children}: ComponentProps ) {

  const { questionsAllowed, transcript } = useSession();
  
  const { setSlideReadyToMoveOn } = useSessionUpdate();

  const { postNotification } = useNotificationUpdate();

  const { getHumanStudentName } = useProfileUpdate();


  // ============================= PROMPT HANDLING =============================
  const handleQuestion = useCallback(async ( question: string, senderName: string, selectedFile?: File ) => { // TODO: Assumes it's a question for now...

    if( !questionsAllowed ) {
      postNotification( QUESTIONS_PAUSED_NOTIFICATION );
      return;
    }

    //setSlideFakeStudentShouldAsk( false );
    //setSlideReadyToMoveOn( false ); // "speak" does this too, BUT, that waits on Server's Response for Model -- May take too long

    let condensedTranscript: string = condenseTranscript( transcript );

    // TODO: Tweak to be right size (and consider if want do this in addTextToTranscript(), i.e. right now keeping ALL Transcript, but may not want -- BUT MAY WANT HAVE FOR DATABASE??)

    const transcriptContext = `Here's the transcript of the entire session, in order from first to last. Use this only as reference to give more context to the prompt you're receiving. Each entry has the format '[[ From Slide {SLIDE NUMBER}|{AUTHOR}: {TEXT} ]]'.\nTranscript: ${condensedTranscript}`;

    const slideContext = ""; // TODO: This came from sessionContext at one point and was used in original app before migration, but now, consider getting from presentationContext or something... (?? may not be in scope)

    //const prompt = `Slide Number: ${sessionDetails.slideNumber}\n${sessionDetails.slideContext} Student's Question: ${question}`;
    //const context = `Always address the student by their name, you are required to say the student's name in your response.\nCurrent Slide Number: ${slideNumber}\n${slideContext}\nThe student's name is ${studentName}, it is required that you always mention the student's name in your response.\n${transcriptContext}`; // TODO: Error Handle cuz "name" may  no tbe on studentInfo
    // TODO: Currently hard-coding Slide Number to 0... NEED PUT IN SESSION CONTEXT, perhaps as "Page Number" instead (curerntly only in PresentationContext, but that isn't in scope here!)
    const context = `Always address the student by their name, you are required to say the student's name in your response.\nCurrent Slide Number: ${0}\n${slideContext}\nThe student's name is ${senderName}, it is required that you always mention the student's name in your response.\n${transcriptContext}`; // TODO: Error Handle cuz "name" may  no tbe on studentInfo
    const prompt = question;
    /*
    const context = `Slide Number: ${sessionDetails.slideNumber}\n${sessionDetails.slideContext}\n`; // TODO: Error Handle cuz "name" may  no tbe on studentInfo
    const prompt = `${question}. My name is ${studentName}, you are required to mention my name in your response.`;
    */

    //console.log("TRANSCRIPT LENGTH: ", condensedTranscript.length);
    //console.log("CONTEXT USING:", context);

    let message: string;
    if( USE_ASSISTANTS ) {
      const assistantResponseOptions = { context, prompt, file: selectedFile };
      message = await getAssistantResponse( assistantResponseOptions );
    }
    else {
      message = await getModelResponse( context, prompt );
    }


    return message;
  }, [questionsAllowed, transcript, postNotification]);

  const handleGeneralPrompt = useCallback(async ( prompt: string ) => {

    if( !prompt ) return;

    //let context = "Do the given command... Respond in a way that makes sense for a real-time lecture. You may be asked to say or behave a certain way, do so, and avoid any additional words like 'yes' or 'as you command', stay specific to the fact this is a real-time lecture and you're being asked to come up with some meaningful message to add to the lecture. DO NOT provide sources or special markdown syntax such as asterisks.";
    let context = "Do the given command. Respond in a way that makes sense for a lecture setting. Respond in plain text without any special formatting. Do not include any mention of the command I am giving, simply act on the command itself without needing to acknowledge it.";

    // TEMP
    const studentName = getHumanStudentName();
    let condensedTranscript = condenseTranscript( transcript );
    const transcriptContext = `Here's the transcript of the entire session, in order from first to last. Use this only as reference to give more context to the prompt you're receiving. Each entry has the format '[[ From Slide {SLIDE NUMBER}|{AUTHOR}: {TEXT} ]]'.\nTranscript: ${condensedTranscript}`;
    //context += " " + `Always address the student by their name, you are required to say the student's name in your response.\nCurrent Slide Number: ${slideNumber}\n${slideContext}\nThe student's name is ${studentName}, it is required that you always mention the student's name in your response.\n${transcriptContext}`; // TODO: Error Handle cuz "name" may  no tbe on studentInfo
    //context += "Do not include any format of the transcript, such as [[]] or other syntax used for the transcript";
    // TEMP

    let message: string;
    if( USE_ASSISTANTS ) {
      const assistantResponseOptions = { context, prompt, messageType: MessageType.CHAT };
      message = await getAssistantResponse( assistantResponseOptions );
    }
    else {
      message = await getModelResponse( context, prompt, PromptType.GENERAL );
    }

    return message;
    //return "";
  }, [transcript]);

  const getModelResponse = useCallback(async ( context: string, prompt: string, promptType: string = PromptType.QUESTION_ANSWER ) => {

    try {
      const modelResponse = await fetchModelResponse( promptType, context, prompt );

      if( !modelResponse ) {
        return SERVER_ERROR_TEXT;
      }

      return modelResponse;
    }
    catch( error ) {
      console.error( "Error:", error );
    }
  }, []);

  const getAssistantResponse = useCallback(async ({ context, prompt, file, promptType = PromptType.QUESTION_ANSWER, messageType = MessageType.CHAT }: any) => {

    try {

      const assistantResponse = await fetchAssistantResponse( promptType, context, prompt, file );

      if( !assistantResponse ) {
        return SERVER_ERROR_TEXT;
      }

      return assistantResponse;
    }
    catch (error) {
      console.error('Error:', error);
    }
  }, []);

  // =============================================================================



  return (
    /* TODO: PromptContext passes FUNCTIONS down that may be better in PromptUpdateContext, as that's the pattern I use in other Contexts - i.e. if a method that is called, it is passed thorugh the Update Context */
    <PromptContext.Provider value={ {} } >
      <PromptUpdateContext.Provider value={ {handleQuestion, handleGeneralPrompt} } >
        {children}
      </PromptUpdateContext.Provider>
    </PromptContext.Provider>
  );

}



function condenseTranscript( currentTranscript: string ) {

  const TRANSCRIPT_CHARACTER_LIMIT = 14000;
  if( currentTranscript.length > TRANSCRIPT_CHARACTER_LIMIT ) {
    let startIndex = currentTranscript.length - TRANSCRIPT_CHARACTER_LIMIT;

    return currentTranscript.slice( startIndex );
  }

  return currentTranscript;
}


